import React, { useEffect, useState } from 'react'
import axiosInstance from '../../utils/Axios';
import AdvertismentCard from './AdvertismentCard';

export default function Advertisment() {
	
	const [data,setData]=useState([]);

	useEffect(()=>{
		axiosInstance.get('/advertisment/advertisment/')
			.then(res=>{
				setData(res?.data);
			})
			.catch(err=>{
				console.log(err)	
			})
	},[])
  return (
	<div>
		{data.length>0&&data.map((item,index)=>(
 			<AdvertismentCard key={index} data={item}/>
		))}
	</div>
  )
}
