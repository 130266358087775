import React, { useEffect, useState } from 'react';
import SubscriptionCard from './fcomponents/SubscriptionCard'; // Adjust the path as necessary
import axiosInstance from '../utils/Axios';

const SubscriptionPage = () => {
  
  const [subscription,setSubscription]=useState([]);
  const [subscriptionUser,setSubscriptionUser]=useState({});

  useEffect(()=>{
    axiosInstance.get('/payment-gateway/subscription-model/')
      .then(res=>{
        setSubscription(res.data)
      })
      .catch(err=>{
        console.log(err.response.data)
      })
  },[])


  useEffect(()=>{
    axiosInstance.get('/payment-gateway/payment/')
      .then(res=>{
        if(res.data.length>0){
          setSubscriptionUser(res?.data[0])
        }
      })
      .catch(err=>{
        console.log(err?.response?.data)
      })
  },[])
  
  return (
    <div className='container mx-auto flex min-h-screen pt-8 md:px-10 px-2'>
      <div className="w-full">
        <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8">
          {subscription?.length>0?subscription?.map((item, index) => (
            <SubscriptionCard key={index} plan={item} isCurrentPlan={subscriptionUser&&subscriptionUser?.subscription_type===item.id?true:false}/>
          )):<p className=' text-center'>Loading...</p>}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPage;
