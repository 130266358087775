import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import ProfileCard from './ProfileCard';


export default function SwipeComponent({ cardData }) {
	const [currentIndex, setCurrentIndex] = useState(0); // Track the current card index
	const [direction, setDirection] = useState(null); // Track the swipe direction
  
	// Handle swipe action
	const handleSwipe = (dir) => {
	  setDirection(dir); // Set the swipe direction
  
	  // Move to the next card after a small delay to allow the exit animation to complete
	  setTimeout(() => {
		setCurrentIndex((prev) => (prev + 1) % cardData.length);
		setDirection(null); // Reset direction after the animation
	  }, 200); // Delay should match the duration of the animation
	};
  
	// Define exit animation based on the direction
	const exitAnimation = {
	  x: direction === 'left' ? -300 : 300, // Exit left or right
	  opacity: 0, // Exit transition duration
	};
  
	// Define entry animation for new card
	const entryAnimation = {
	  x: 0,
	  scale:1,
	  opacity: 1, // Entry transition duration
	};

  return (
    <div className="relative flex flex-col items-center md:p-4 p-2 min-h-screen">
      <AnimatePresence mode='wait'>
        {/* Apply motion directly to the ProfileCard */}
        <motion.div
          key={currentIndex} // Unique key to trigger re-render
          initial={{ x: 0, opacity: 0,scale:0.9 }} // Initial state for the card
          animate={entryAnimation} // Entry animation
          exit={exitAnimation} // Exit animation based on direction
		  transition={{type:'tween',duration: 0.6,ease:'circIn'}}
          className="relative w-full flex flex-col items-center"
        >
          <ProfileCard data={cardData[currentIndex]} xmark={() => handleSwipe('left')} heart={() => handleSwipe('right')} />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
