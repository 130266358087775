import React from 'react';

const Matrimony101 = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">Matrimony® 101</h1>
      <p className="text-gray-700 mb-4">
        Welcome to Second Inning Matrimony, your trusted partner in finding love and companionship. 
        We believe that everyone deserves a second chance at happiness, and we are dedicated to helping 
        you navigate the journey of finding your perfect match.
      </p>
      <h2 className="text-2xl font-semibold mb-4">About Us</h2>
      <p className="text-gray-700 mb-4">
        At Second Inning Matrimony, we understand that relationships are built on trust, mutual respect, 
        and shared values. Our platform is designed to connect individuals who are looking for meaningful 
        relationships, whether you're seeking friendship, companionship, or love.
      </p>
      <h3 className="text-xl font-semibold mb-2">Our Mission</h3>
      <p className="text-gray-700 mb-4">
        Our mission is to create a safe and supportive environment where you can explore potential 
        connections. We focus on providing personalized matchmaking services tailored to your unique 
        preferences and requirements.
      </p>
      <h3 className="text-xl font-semibold mb-2">Our Services</h3>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Personalized matchmaking based on your interests and values</li>
        <li>Access to a wide network of individuals looking for relationships</li>
        <li>Confidentiality and security for all our members</li>
        <li>24/7 member support to assist you at any stage of your journey</li>
      </ul>
      <h3 className="text-xl font-semibold mb-2">Why Choose Us?</h3>
      <p className="text-gray-700 mb-4">
        Choosing Second Inning Matrimony means choosing a supportive community that values love and 
        companionship. We are committed to helping you find your soulmate in a friendly and 
        understanding atmosphere. Join us today, and take the first step towards a beautiful 
        relationship.
      </p>
      <h3 className="text-xl font-semibold mb-2">Get Started</h3>
      <p className="text-gray-700 mb-4">
        Ready to find your match? Sign up now and begin your journey with Second Inning Matrimony. 
        Let us help you discover love again!
      </p>
    </div>
  );
};

export default Matrimony101;
