import { createSlice } from "@reduxjs/toolkit";


const initialState={
	theme: (localStorage.getItem('color-theme') || 'dark').replace(/"/g, ""),
}
const themeSlice=createSlice({
	name:'theme',
	initialState,
	reducers:{
		themeToggle:(state,actions)=>{
			state.theme=actions.payload;
		},
		
	}
})

export const {themeToggle}=themeSlice.actions
export default themeSlice.reducer
