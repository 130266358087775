import React, { useEffect, useState } from "react";

import "../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import FormInput from "./Form/FormInput";
import MainButton from "./Button/MainButton";
import axiosInstance from "../utils/Axios";
import { useDispatch } from "react-redux";
import { userLogin } from "../app/User";
import { toast } from "sonner";

const Login = () => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const {state}=useLocation();

    const  [loading,setLoading]=useState(false);

    useEffect(()=>{
      if(!state){
        navigate('/')
      }
    },[state])


    const formSchema = z.object({
        otp: z
          .string()
          .nonempty("Phone number is required")
          .regex(/^[0-9]{6}$/, "OTP must be 6 digits long"),
      });

  const { control, handleSubmit } = useForm({
    defaultValues: {
        otp: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data) => {
    data.phone_no=state;
    setLoading(true);
    axiosInstance.post('account/verify-otp/',data)
      .then(res=>{
          localStorage.setItem('isLoggedIn',true)
          localStorage.setItem('userInfo',JSON.stringify(res?.data))
          localStorage.setItem('accessToken',res?.data?.access)
          dispatch(userLogin(res?.data));
          toast.success(res?.data?.detail??'Login Successful')
          if(res?.data?.is_profile_created){
            navigate('/profile')
          }else{
            navigate('/multi-form-profile',{state:'new'})
          }
      })
      .catch(err=>{
        toast.error(err.response.data.detail)
        setLoading(false);
      })
  };

  const handleResend=()=>{
    axiosInstance.post('account/resend-otp/',{phone_no:state})
    .then(res=>{
        toast.success(res?.data?.detail??'OTP sent succesfully')
    })
    .catch(err=>{
        console.log(err?.response?.data)
    })
  }

  return (
    <section class="bg-gray-50 ">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Verify OTP
            </h1>
            <form
              class="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormInput
                label={'OTP'}
                type={'number'}
                placeholder={'Enter OTP'}
                name={'otp'}
                control={control}
                maxLength={6}
                onInput={(e) => {
                  // Prevent entering more than 6 digits
                  if (e.target.value.length > 6) {
                    e.target.value = e.target.value.slice(0, 6);
                  }
                }}
              />
              <p onClick={handleResend} className="text-orange-500 cursor-pointer">Resend OTP</p>
              <MainButton type={'submit'} title={loading?'loading':'submit'} isDisabled={loading} />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
