import React from 'react';

const TermsOfUse = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>
      <p className="text-gray-700 mb-4">
        Welcome to Second Inning Matrimony. By accessing our website and using our services, 
        you agree to comply with and be bound by the following terms and conditions of use.
      </p>
      
      <h2 className="text-2xl font-semibold mb-4">1. Acceptance of Terms</h2>
      <p className="text-gray-700 mb-4">
        By accessing this website, you acknowledge that you have read, understood, and agree 
        to be bound by these terms. If you do not agree to these terms, you should not use our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. User Responsibilities</h2>
      <p className="text-gray-700 mb-4">
        Users are responsible for maintaining the confidentiality of their account information 
        and for all activities that occur under their account. You agree to notify us immediately 
        of any unauthorized use of your account.
      </p>

      <h2 className="text-2xl font-semibold mb-4">3. Content Standards</h2>
      <p className="text-gray-700 mb-4">
        You are solely responsible for any content you post, upload, or otherwise contribute 
        to our website. You agree not to post any content that is unlawful, harmful, threatening, 
        abusive, harassing, defamatory, or otherwise objectionable.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. Third-Party Links</h2>
      <p className="text-gray-700 mb-4">
        Our website may contain links to third-party websites. We do not endorse or assume 
        any responsibility for the content or practices of these websites. You access them at 
        your own risk.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
      <p className="text-gray-700 mb-4">
        Second Inning Matrimony shall not be liable for any direct, indirect, incidental, 
        special, consequential, or punitive damages arising from your use of, or inability to 
        use, the website or any services provided.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Indemnification</h2>
      <p className="text-gray-700 mb-4">
        You agree to indemnify, defend, and hold harmless Second Inning Matrimony, its 
        affiliates, and their respective officers, directors, employees, and agents from any 
        claims, losses, liabilities, damages, costs, or expenses arising from your use of the 
        website or any violation of these terms.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. Changes to Terms</h2>
      <p className="text-gray-700 mb-4">
        We reserve the right to modify these terms at any time. Any changes will be effective 
        immediately upon posting on the website. Your continued use of the website after any 
        changes constitutes your acceptance of the new terms.
      </p>

      <h2 className="text-2xl font-semibold mb-4">8. Governing Law</h2>
      <p className="text-gray-700 mb-4">
        These terms are governed by and construed in accordance with the laws of the 
        jurisdiction in which Second Inning Matrimony operates. Any disputes arising under 
        these terms shall be subject to the exclusive jurisdiction of the courts in that 
        jurisdiction.
      </p>

      <h2 className="text-2xl font-semibold mb-4">9. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about these Terms of Use, please <a href="/contact" className="text-blue-500 hover:underline">contact us</a>.
      </p>
    </div>
  );
};

export default TermsOfUse;
