import React from 'react';

const TopRankingCities = () => {
  const cities = [
    { name: 'Mumbai', description: 'The bustling city known for its vibrant culture and opportunities.' },
    { name: 'Delhi', description: 'The capital city, rich in history and a hub for many cultures.' },
    { name: 'Bangalore', description: 'The tech capital of India, known for its pleasant climate and greenery.' },
    { name: 'Hyderabad', description: 'Famous for its cuisine, particularly the Hyderabadi Biryani.' },
    { name: 'Chennai', description: 'Known for its classical music and dance, as well as beautiful temples.' },
    { name: 'Kolkata', description: 'The cultural capital, famous for its literature and arts.' },
    { name: 'Ahmedabad', description: 'A city known for its textile industry and heritage sites.' },
    { name: 'Pune', description: 'A city with a rich historical background and a growing IT industry.' },
    { name: 'Jaipur', description: 'The Pink City, known for its beautiful palaces and forts.' },
    { name: 'Lucknow', description: 'Famous for its historical monuments and rich cuisine.' },
  ];

  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">Top Ranking Cities for Matrimony</h1>
      <p className="text-gray-700 mb-4">
        Explore the top cities in India where you can find potential matrimonial matches. 
        Each city has its own unique culture, lifestyle, and opportunities.
      </p>

      <ul className="list-disc ml-5 mt-4">
        {cities.map((city, index) => (
          <li key={index} className="mb-4">
            <h2 className="text-xl font-semibold text-gray-900">{city.name}</h2>
            <p className="text-gray-500">{city.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopRankingCities;
