import React, { useState } from 'react'

import '../App.css';
import { Link, useNavigate } from 'react-router-dom';
import FormInput from './Form/FormInput';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import MainButton from './Button/MainButton';
import axiosInstance from '../utils/Axios';
import { toast } from 'sonner';

const Register = () => {
    const navigate=useNavigate();
    const [loading,setLoading]=useState(false)
    const [code,setCode]=useState('+91');

    const formSchema = z.object({
        phone_no: z
          .string()
          .nonempty("Phone number is required")
          .regex(/^[0-9]{10}$/, "Phone number must be 10 digits long"),
      });

  const { control, handleSubmit } = useForm({
    defaultValues: {
        phone_no: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = (data) => {
    data.phone_no=code+data.phone_no
    setLoading(true)
    axiosInstance.post('/account/register/',data)
      .then(res=>{
        toast.success(res?.data?.detail??"OTP sent successfully")
        setLoading(false);
        navigate('/login',{state:data.phone_no})
      })
      .catch(err=>{
        toast.error(err?.response?.data?.detail)
        setLoading(false);
      })
  };

    return (
<section class="bg-gray-50 ">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Login/Register to your account
            </h1>
            <form
              class="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className='flex items-end'>
                <select className=' outline-none ring-0 border h-12 rounded-md' name="" id="" onChange={(e)=>setCode(e.target.value)} value={code}>
                  <option value="+91">+91</option>
                  <option value="+21">+21</option>
                </select>
                <FormInput
                  label={'Phone Number'}
                  type={'number'}
                  placeholder={'Enter phone number'}
                  name={'phone_no'}
                  control={control}
                  maxLength={10}
                  onInput={(e) => {
                    // Prevent entering more than 6 digits
                    if (e.target.value.length > 10) {
                    e.target.value = e.target.value.slice(0, 10);
                    }
                  }}
                />
              </div>
              
              <MainButton type={'submit'} title={loading?'loading':'submit'} isDisabled={loading}/>
            </form>
          </div>
        </div>
      </div>
    </section>
    )
}

export default Register