import React, { useEffect, useState } from "react";
import axiosInstance from "../../utils/Axios";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const PreferedForm = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [step, setStep] = useState(1); // Step tracking
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    prefred_gender: "",
    prefred_height: "",
    prefred_location: "",
    prefred_religion: "",
    prefred_income: "",
    family_type: "",
    prefred_smoke: "",
    prefred_drink: "",
  });
  const [errors, setErrors] = useState({}); // For validation errors


  useEffect(()=>{
	axiosInstance.get('/account/prefrence/')
		.then(res=>{
			if(res.data.length>0){
				setFormData({
					prefred_gender: res?.data?.[0].prefred_gender,
					prefred_height: res?.data?.[0].prefred_height,
					prefred_location: res?.data?.[0].prefred_location,
					prefred_religion: res?.data?.[0].prefred_religion,
					prefred_income: res?.data?.[0].prefred_income,
					family_type: res?.data?.[0].family_type,
					prefred_smoke: res?.data?.[0].prefred_smoke,
					prefred_drink: res?.data?.[0].prefred_drink,
				})
			}
		})
		.catch(err=>{

		})
  },[])

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate the fields based on the current step
  const validateStep = () => {
    let newErrors = {};
    if (step === 1) {
      if (!formData.prefred_gender)
        newErrors.prefred_gender = "Gender is required";
      if (!formData.prefred_height)
        newErrors.prefred_height = "Height required";
      if (!formData.prefred_location)
        newErrors.prefred_location = "Location is required";
      if (!formData.prefred_religion)
        newErrors.prefred_religion = "Religion is required";
      if (!formData.prefred_income)
        newErrors.prefred_income = "Income is required";
      if (!formData.family_type)
        newErrors.family_type = "Family type is required";
      if (!formData.prefred_smoke)
        newErrors.prefred_smoke = "Smoke preference is required";
      if (!formData.prefred_drink)
        newErrors.prefred_drink = "Drink preference is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Navigate to the next step
  const handleNext = () => {
    if (validateStep()) {
      handleSubmit();
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    setLoading(true);
    axiosInstance
      .post("/account/prefrence/", formData)
      .then(() => {
        toast.success("Success");
        setLoading(false);
        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  return (
    <div className="max-w-md mx-auto p-4 h-screen space-y-6">
      {step === 1 && (
        <div className="space-y-4">
          <h2 className="text-xl font-bold">Preferred Preferences</h2>

          <select
            name="prefred_gender"
            value={formData.prefred_gender}
            onChange={handleChange}
            className="border p-2 rounded w-full"
          >
            <option value="">Select Gender</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
          </select>
          {errors.prefred_gender && (
            <p className="text-red-500">{errors.prefred_gender}</p>
          )}

          <input
            type="number"
            name="prefred_height"
            value={formData.prefred_height}
            onChange={handleChange}
            placeholder="Height(cm)"
            className="border p-2 rounded w-full"
          />
          {errors.prefred_height && (
            <p className="text-red-500">{errors.prefred_height}</p>
          )}

          <input
            type="text"
            name="prefred_location"
            value={formData.prefred_location}
            onChange={handleChange}
            placeholder="Location"
            className="border p-2 rounded w-full"
          />
          {errors.prefred_location && (
            <p className="text-red-500">{errors.prefred_location}</p>
          )}

          <select
            name="prefred_religion"
            value={formData.prefred_religion}
            onChange={handleChange}
            className="border p-2 rounded w-full"
          >
            <option value="">Select Religion</option>
            <option value="Islam">Islam</option>
            <option value="Christianity">Christianity</option>
            <option value="Hinduism">Hinduism</option>
            <option value="Buddhism">Buddhism</option>
            <option value="Sikhism">Sikhism</option>
            <option value="Judaism">Judaism</option>
            <option value="Atheism">Atheism</option>
            <option value="Agnosticism">Agnosticism</option>
            <option value="Other">Other</option>
            {/* Add other options as needed */}
          </select>
          {errors.prefred_religion && (
            <p className="text-red-500">{errors.prefred_religion}</p>
          )}

          <input
            type="number"
            name="prefred_income"
            value={formData.prefred_income}
            onChange={handleChange}
            placeholder="Income"
            className="border p-2 rounded w-full"
          />
          {errors.prefred_income && (
            <p className="text-red-500">{errors.prefred_income}</p>
          )}

          <select
            name="family_type"
            value={formData.family_type}
            onChange={handleChange}
            className="border p-2 rounded w-full"
          >
            <option value="">Select Family Type</option>
            <option value="Nuclear Family">Nuclear Family</option>
            <option value="Joint Family">Joint Family</option>
            <option value="UpperMiddle Class Family">
              Upper Middle Class Family
            </option>
            <option value="Middle Family">Middle Class Family</option>
            <option value="Affluent Family">Affluent Family</option>
            <option value="Business Family">Business Family</option>
            <option value="Liberal Family">Liberal Family</option>
            <option value="Conservative Family">Conservative Family</option>
            <option value="Religious Family">Religious Family</option>
            <option value="Rich Family">Rich Family</option>
            <option value="Moderate Family">Moderate Family</option>
            {/* Add other options as needed */}
          </select>
          {errors.family_type && (
            <p className="text-red-500">{errors.family_type}</p>
          )}

          <select
            name="prefred_smoke"
            value={formData.prefred_smoke}
            onChange={handleChange}
            className="border p-2 rounded w-full"
          >
            <option value="">Do you Smoke ?</option>
            <option value="false">No</option>
            <option value="true">Yes</option>
          </select>
          {errors.prefred_smoke && (
            <p className="text-red-500">{errors.prefred_smoke}</p>
          )}

          <select
            name="prefred_drink"
            value={formData.prefred_drink}
            onChange={handleChange}
            className="border p-2 rounded w-full"
          >
            <option value="">Do you Drink ?</option>
            <option value="false">No</option>
            <option value='true'>Yes</option>
          </select>
          {errors.prefred_drink && (
            <p className="text-red-500">{errors.prefred_drink}</p>
          )}

          <button
            onClick={handleNext}
            className={`bg-orange-500 text-white py-2 px-4 rounded ${
              loading && "opacity-50"
            }`}
            disabled={loading}
          >
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default PreferedForm;
