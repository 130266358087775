import React from 'react'
import Success from './fcomponents/Success'
import About from './fcomponents/About'
import Advertisment from './fcomponents/Advertisment'

const Frontend = () => {
  return (
   <>
   <About/>
   <Advertisment/>
   <Success/> 
   </>
  )
}

export default Frontend