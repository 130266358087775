import React from "react";
import Advertisment from "./Advertisment";

const About = () => {
  return (
    <>
      <main>
        <div
          className="relative py-16 flex content-center items-center justify-center"
          style={{ minHeight: "75vh" }}
        >
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url(https://images.pexels.com/photos/2055225/pexels-photo-2055225.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Welcome to Second Inning Matrimony!
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
                    At Second Inning, we assist you in legally and socially
                    organizing your marriage, taking responsibility for event
                    arrangements as well. You can entrust us with the complete
                    formalities and management of your marriage ceremony. We
                    understand that human nature tends to present only the
                    brighter side in their CV/biodata to achieve their
                    interests. It is important to be aware of the darker sides
                    as well and make a thoughtful decision before marriage,
                    instead of being swept away by emotions.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <section className="pb-20 bg-gray-300 -mt-24">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Empowerment</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      We help individuals reignite their passions and live their
                      Second Inning Matrimony with purpose, whether it's through
                      new career opportunities, personal growth, or creative
                      exploration.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Community</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      We foster a community where people connect, share stories,
                      and support each other in making the most of life after
                      retirement or a major life transition.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Creativity</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      We encourage creativity through workshops, events, and
                      mentorship, helping individuals discover new talents and
                      rekindle old ones.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Our Specialties
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  We provide end to end support on each step like Medical,
                  Social, Financial, Professional and legal verification.Our
                  Voluntary work for social welfareWe organize Group marriages
                  for the Economically weaker section at almost free of cost.
                </p>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-pink-600">
                  <img
                    alt="..."
                    src="https://images.pexels.com/photos/27876538/pexels-photo-27876538/free-photo-of-a-man-and-woman-in-traditional-attire-at-their-wedding.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block"
                      style={{ height: "95px", top: "-94px" }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-pink-600 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-black">Our Vision</h4>
                    <p className="text-md font-light mt-2 text-black">
                      To create a platform where we can find the best life
                      partner without any obstacle and hesitation.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-12 bg-gray-100">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            {/* First Card */}
            <div className="flex flex-col md:flex-row items-center mb-12">
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mb-6 md:mb-0">
                <img
                  alt="..."
                  className="w-full rounded-lg shadow-lg"
                  src="https://images.pexels.com/photos/14825258/pexels-photo-14825258.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-heart"></i>
                  </div>
                  <h3 className="text-3xl font-semibold mb-4">
                    Before choosing a life partner, verify these:
                  </h3>
                  <ul className="list-disc list-inside text-lg space-y-2">
                    <li>Financial status (job, business, property)</li>
                    <li>Social status (single/divorced/widowed)</li>
                    <li>Family situation (disputes, criminal records, etc.)</li>
                    <li>Mental and emotional health</li>
                    <li>Bad habits or red flags</li>
                    <li>Family's mindset and lifestyle</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Second Card */}
            <div className="flex flex-col md:flex-row items-center mb-12">
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-blue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-300">
                    <i className="fa-solid fa-circle-check"></i>
                  </div>
                  <h3 className="text-3xl font-semibold mb-4">Services</h3>
                  <ul className="list-disc list-inside text-lg space-y-2">
                    <li>Event Management</li>
                    <li>End-to-End Marriage Support</li>
                    <li>Multi-Step Verification for Bride & Groom</li>
                    <li>Legal Assistance</li>
                    <li>Group Marriages for Financially Weak Couples</li>
                    <li>And Many More...</li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mb-6 md:mb-0">
                <img
                  alt="..."
                  className="w-full rounded-lg shadow-lg"
                  src="https://images.pexels.com/photos/17000478/pexels-photo-17000478/free-photo-of-picture-of-indian-newlywed.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>

          {/* Third Section */}
          <div className="container mx-auto px-4 py-12">
            <div className="flex flex-col md:flex-row items-center mb-12">
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mb-6 md:mb-0">
                <img
                  alt="..."
                  className="w-full rounded-lg shadow-lg"
                  src="https://images.pexels.com/photos/6498019/pexels-photo-6498019.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-heart"></i>
                  </div>
                  <h3 className="text-3xl font-semibold mb-4">
                    Questions to Reflect Before Commitment
                  </h3>
                  <ul className="list-disc list-inside text-lg space-y-2">
                    <li>Do your long-term goals align with your partner's?</li>
                    <li>How do you both handle conflict?</li>
                    <li>Are you both open to growth in communication?</li>
                    <li>What are your family life expectations?</li>
                    <li>How do you manage financial planning?</li>
                    <li>What core values guide your relationship?</li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Fourth Card */}
            <div className="flex flex-col md:flex-row items-center">
              <div className="w-full md:w-6/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-blue-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blue-300">
                    <i className="fa-solid fa-circle-check"></i>
                  </div>
                  <h3 className="text-3xl font-semibold mb-4">
                    Our Matrimony Services
                  </h3>
                  <ul className="list-disc list-inside text-lg space-y-2">
                    <li>Personalized Matchmaking</li>
                    <li>Pre-Marriage Counseling</li>
                    <li>Astrological Matching (Kundli Milan)</li>
                    <li>Background Verification for Bride and Groom</li>
                    <li>Exclusive Marriage Events & Meetups</li>
                    <li>Post-Marriage Support & Guidance</li>
                  </ul>
                </div>
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4 mb-6 md:mb-0">
                <img
                  alt="..."
                  className="w-full rounded-lg shadow-lg"
                  src="https://images.pexels.com/photos/265722/pexels-photo-265722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="relative py-12 text-center">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4">
            <h2 className="text-4xl font-semibold text-gray-800 mb-6">
              Discover the True Essence of Matrimony
            </h2>
            <p className="text-lg text-gray-600 leading-relaxed max-w-full mx-auto">
              Matrimony is not merely a ceremonial union between two people; it
              is the beautiful and powerful convergence of two souls, two
              families, and two futures. It is the bond that binds hearts,
              hopes, and aspirations into a single journey, built on trust,
              understanding, and shared dreams.
            </p>
            <p className="text-lg text-gray-600 leading-relaxed mt-6 max-w-full mx-auto">
              In today's world, the decision to marry is one of the most
              significant life choices. It is more than just finding a
              partner—it's about finding a companion who shares your values,
              your vision, and your purpose. This is why modern matrimony
              requires careful thought, emotional connection, and a deep sense
              of compatibility.
            </p>
          </div>

          <div className="container mx-auto px-4 mt-12">
            <h3 className="text-3xl font-semibold text-gray-800 mb-6">
              Why Choose Our Matrimony Services?
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed max-w-full mx-auto">
              We understand the importance of this life-changing decision. Our
              platform is designed to help you find a partner who not only
              matches your preferences but also resonates with your beliefs and
              aspirations. Our multi-layered verification process ensures that
              you are introduced to genuine and trustworthy individuals who are
              serious about building a meaningful relationship.
            </p>
            <p className="text-lg text-gray-600 leading-relaxed mt-6 max-w-full mx-auto">
              Whether you are looking for a life partner, need help with wedding
              planning, or require post-marriage counseling, we are here to
              support you every step of the way. Our dedicated team of
              professionals is committed to making your matrimonial journey
              smooth, joyful, and fulfilling.
            </p>
          </div>

          <div className="container mx-auto px-4 mt-12">
            <h3 className="text-3xl font-semibold text-gray-800 mb-6">
              Our Core Matrimony Services
            </h3>
            <p className="text-lg text-gray-600 leading-relaxed max-w-full mx-auto">
              From personalized matchmaking to family consultations, we provide
              a range of services tailored to meet your specific needs:
            </p>
            <ul className="list-inside list-disc text-lg text-gray-600 leading-relaxed max-w-full mx-auto mt-6">
              <li>Verified profiles with in-depth background checks</li>
              <li>One-on-one matchmaking services</li>
              <li>Pre-marriage and post-marriage counseling</li>
              <li>Family mediation and support services</li>
              <li>End-to-end wedding planning assistance</li>
            </ul>
          </div>
        </section>

        <section className="py-12 bg-gray-50">
          <div className="container mx-auto px-4">
            <h2 className="text-3xl font-semibold text-gray-800 text-center mb-12">
              Our Key Matrimony Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {/* Card 1 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-pink-600 text-5xl mb-4">
                  <i className="fas fa-user-check"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  Verified Profiles
                </h3>
                <p className="text-gray-600">
                  Each profile is carefully verified through a multi-step
                  process to ensure authenticity and trust.
                </p>
              </div>

              {/* Card 2 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-blue-600 text-5xl mb-4">
                  <i className="fas fa-handshake"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  Personalized Matchmaking
                </h3>
                <p className="text-gray-600">
                  We provide one-on-one matchmaking services that cater to your
                  personal preferences and values.
                </p>
              </div>

              {/* Card 3 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-green-600 text-5xl mb-4">
                  <i className="fas fa-calendar-check"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2">Event Management</h3>
                <p className="text-gray-600">
                  From engagement to wedding, we help manage every event with
                  precision and care.
                </p>
              </div>

              {/* Card 4 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-red-600 text-5xl mb-4">
                  <i className="fas fa-users"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2">Family Support</h3>
                <p className="text-gray-600">
                  We work closely with families to ensure a smooth, harmonious
                  journey towards matrimony.
                </p>
              </div>

              {/* Card 5 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-yellow-600 text-5xl mb-4">
                  <i className="fas fa-heart"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  Pre & Post-Marriage Counseling
                </h3>
                <p className="text-gray-600">
                  Our counselors are here to provide guidance and support both
                  before and after the marriage.
                </p>
              </div>

              {/* Card 6 */}
              <div className="bg-white shadow-md rounded-lg p-6 text-center">
                <div className="text-indigo-600 text-5xl mb-4">
                  <i className="fas fa-gavel"></i>
                </div>
                <h3 className="text-xl font-semibold mb-2">Legal Assistance</h3>
                <p className="text-gray-600">
                  Get help with legal documentation and marriage registration to
                  ensure a smooth process.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default About;
