import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      <p className="text-gray-700 mb-4">
        Welcome to the Privacy Policy of Second Inning Matrimony. Your privacy is important to us. 
        This policy explains how we collect, use, and protect your personal information.
      </p>

      <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
      <p className="text-gray-700 mb-4">
        We may collect the following types of personal information:
        <ul className="list-disc ml-5 mt-2">
          <li>Name</li>
          <li>Email Address</li>
          <li>Phone Number</li>
          <li>Demographic Information</li>
          <li>Payment Information (when applicable)</li>
        </ul>
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">
        Your information may be used in the following ways:
        <ul className="list-disc ml-5 mt-2">
          <li>To provide, maintain, and improve our services</li>
          <li>To communicate with you, including responding to inquiries</li>
          <li>To process transactions and send you confirmation</li>
          <li>To send periodic emails regarding your order or other products and services</li>
          <li>To personalize your experience on our website</li>
        </ul>
      </p>

      <h2 className="text-2xl font-semibold mb-4">3. Information Protection</h2>
      <p className="text-gray-700 mb-4">
        We implement a variety of security measures to maintain the safety of your personal information 
        when you place an order or enter, submit, or access your personal information. 
        These security measures include password protected directories and databases to safeguard your 
        information.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. Sharing Your Information</h2>
      <p className="text-gray-700 mb-4">
        We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information 
        unless we provide users with advance notice. This does not include website hosting partners and other 
        parties who assist us in operating our website, conducting our business, or servicing you, so long as 
        those parties agree to keep this information confidential.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. Third-Party Services</h2>
      <p className="text-gray-700 mb-4">
        Occasionally, at our discretion, we may include or offer third-party products or services on our website. 
        These third-party sites have separate and independent privacy policies. Therefore, we have no responsibility 
        or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity 
        of our site and welcome any feedback about these sites.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Changes to This Privacy Policy</h2>
      <p className="text-gray-700 mb-4">
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new 
        Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. 
        Changes to this Privacy Policy are effective when they are posted on this page.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about this Privacy Policy, please <a href="mailto:secondinningmatrimonial@gmail.com" className="text-blue-500 hover:underline"> secondinningmatrimonial@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
