import React from 'react';
import { BASE_URL } from '../../utils/Constant';

export default function ProfileCard({ data, onClick,xmark,heart }) {
  // Destructure the properties from the data object
  const {
    name,
    date_of_birth,
    job,
    photo1,
    location,
    bio,
  } = data;

  const calculateAge = (dob) => {
    const today = new Date(); // Get the current date
    const birthDate = new Date(dob); // Create a date object for the DOB
    let age = today.getFullYear() - birthDate.getFullYear(); // Calculate the year difference
    const monthDifference = today.getMonth() - birthDate.getMonth(); // Calculate the month difference

    // Adjust the age if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age; // Return the calculated age
};

  return (
    <div className="w-full sm:w-72 md:w-60 lg:w-80 xl:w-[390px] 2xl:w-[420px] h-[35rem] bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="relative cursor-pointer" onClick={onClick}>
        {/* Use the dynamic profile image from the data */}
        <img
          src= {photo1 || 'https://placehold.co/1920x1080'}
          alt="Profile Image"
          className="w-full h-52 object-cover"
        />
      </div>
      <div className="p-4 cursor-pointer" onClick={onClick}>
        {/* Display the dynamic name and age */}
        <h1 className="text-lg font-bold">
          {name}, {calculateAge(date_of_birth)}
        </h1>
        {/* Display the dynamic job title and company */}
        <p className="text-gray-600 text-sm mt-1 flex items-center">
          <i className="fas fa-briefcase mr-2"></i> {job?'Job':'Business'}
        </p>
        {/* Display the dynamic education */}
        {/* <p className="text-gray-600 text-sm mt-1 flex items-center">
          <i className="fas fa-graduation-cap mr-2"></i> {education}
        </p> */}
        {/* Display the dynamic location */}
        <p className="text-gray-600 text-sm mt-1 flex items-center">
          <i className="fas fa-map-marker-alt mr-2"></i> {location}
        </p>
      </div>
      <div className="px-4 py-2 cursor-pointer" onClick={onClick}>
        <h2 className="text-md font-semibold">About</h2>
        {/* Display the dynamic about section */}
        <p className="text-gray-600 text-sm mt-2">{bio.slice(0,160)}...</p>
      </div>
      <div className="flex justify-center items-center p-4">
        {/* Dislike Button */}
        {/* <button className="flex items-center justify-center w-12 h-12 rounded-full bg-red-500 text-white text-xl" onClick={xmark}>
          <i className="fas fa-times"></i>
        </button> */}
        {/* Like Button */}
        <button className="flex items-center justify-cente px-5 py-1 rounded-lg bg-orange-500 text-white text-xl" onClick={onClick}>
          View
        </button>
      </div>
    </div>
  );
}
