import React from 'react'
import { BASE_URL } from '../../utils/Constant';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function AdvertismentCard({data}) {
	const {image,image1,image2,video}=data;

  return (
	<div className='w-full h-full px-5'>
		<Carousel showStatus={false} showThumbs={false} autoPlay={true} infiniteLoop={true} showArrows={true} swipeable={true} > 
               {image&&<img className='w-full h-[500px] object-contain my-8' src={BASE_URL+ image} alt="" /> }
               {image1&&<img className='w-full h-[500px] object-contain my-8' src={BASE_URL+ image1} alt="" /> }
			   {image2&&<img className='w-full h-[500px] object-contain my-8' src={BASE_URL+ image2} alt="" /> }
			   {video&&<video className='w-full h-[500px] object-contain my-8' autoPlay muted loop  src={BASE_URL + video} alt="" />}
    	</Carousel>
	</div>
  )
}
