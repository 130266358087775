import React from 'react';

const PrivacyAndYou = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">Privacy & You</h1>
      <p className="text-gray-700 mb-4">
        At Second Inning Matrimony, we value your privacy and are committed to protecting 
        your personal information. This Privacy Policy outlines how we collect, use, 
        and safeguard your information when you use our services.
      </p>
      
      <h2 className="text-2xl font-semibold mb-4">1. Information We Collect</h2>
      <p className="text-gray-700 mb-4">
        We collect information from you when you register on our site, log in, or 
        use our services. This may include your name, email address, phone number, 
        and other personal details necessary for providing our services.
      </p>

      <h2 className="text-2xl font-semibold mb-4">2. How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">
        Your information may be used in the following ways:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>To personalize your experience and provide tailored content.</li>
        <li>To improve our website and services based on your feedback.</li>
        <li>To process transactions and send you confirmations.</li>
        <li>To communicate with you regarding your account, services, and promotions.</li>
      </ul>

      <h2 className="text-2xl font-semibold mb-4">3. How We Protect Your Information</h2>
      <p className="text-gray-700 mb-4">
        We implement a variety of security measures to maintain the safety of your personal 
        information. These include encryption, secure servers, and regular security assessments.
      </p>

      <h2 className="text-2xl font-semibold mb-4">4. Sharing Your Information</h2>
      <p className="text-gray-700 mb-4">
        We do not sell, trade, or otherwise transfer your personal information to outside parties, 
        except to provide you with our services, comply with the law, or protect our rights.
      </p>

      <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
      <p className="text-gray-700 mb-4">
        You have the right to request access to the personal information we hold about you. 
        You may also request corrections to your data or request deletion in certain circumstances.
      </p>

      <h2 className="text-2xl font-semibold mb-4">6. Changes to Our Privacy Policy</h2>
      <p className="text-gray-700 mb-4">
        We may update this Privacy Policy periodically. We will notify you of any changes by posting 
        the new Privacy Policy on this page and updating the effective date.
      </p>

      <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions about this Privacy Policy or our practices regarding your personal 
        information, please feel free to <a href="mailto:secondinningmatrimonial@gmail.com" className="text-blue-500 hover:underline"> secondinningmatrimonial@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyAndYou;
