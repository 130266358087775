import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../utils/Axios';
import { userLogout } from '../app/User';
import { BASE_URL } from '../utils/Constant';

const ProfilePage = () => {
  const [userData, setUserData] = useState(null);
  const [subscriptionUser, setSubscriptionUser] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const userProfile=useSelector(state=>state.user.userProfile);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Fetch user profile data
  useEffect(() => {
    if(userProfile.is_profile_created){
      axiosInstance.get('/account/user-data/')
      .then(res => {
          setUserData(res?.data)
      })
      .catch(err => {
        console.log(err?.response?.data);
      });
    }else{
      navigate('/multi-form-profile', { state: 'new' });
    }
  }, [userProfile.is_profile_created]);
  
  // // Fetch subscription data
  // useEffect(() => {
  //   axiosInstance.get('/payment-gateway/payment/')
  //     .then(res => {
  //       if (res.data.length > 0) {
  //         setSubscriptionUser(res?.data[0]);
  //       }
  //     })
  //     .catch(err => {
  //       console.log(err.response.data);
  //     });
  // }, []);

  const images = [
    userData?.photo1,
    userData?.photo2,
    userData?.photo3,
    userData?.photo4,
    userData?.photo5,
  ].filter(Boolean); // Filter out null or undefined images

  // Next and Previous image handlers
  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleLogout = () => {
    dispatch(userLogout());
    navigate('/register')
  };

  // Placeholder or dynamic rendering function
  const displayValue = (value, placeholder = "Enter Now") => {
    return typeof value === 'string' && value.trim() !== "" ? value : placeholder;
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Header */}
      <header className=" p-4 text-white">
        {/* <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-xl md:text-2xl font-bold">My Profile</h1>
          <button className="bg-yellow-500 px-2 py-1 md:px-4 md:py-2 rounded-full">Upgrade Now</button>
        </div> */}
      </header>

      {/* Profile Section */}
      <div className="container mx-auto mt-6 px-4">
        <div className="bg-white shadow-md rounded-lg p-4">
          <div className="flex flex-col md:flex-row">
            {/* Profile Picture and Manage Profile */}
            <div className="md:w-1/3 text-center mb-4 md:mb-0">
              <div className="w-32 h-32 md:w-40 md:h-40 bg-gray-200 mx-auto rounded-full flex items-center justify-center">
                {images.length > 0 ? (
                  <img
                    src={BASE_URL + images[currentImageIndex]}
                    alt="Profile Pic"
                    className="w-full h-full rounded-full object-cover"
                  />
                ) : (
                  <span>No Photo</span>
                )}
              </div>
              {images.length > 1 && (
                <div className="flex justify-between mt-4">
                  <button onClick={prevImage} className="bg-orange-300 p-2 rounded-full">Prev</button>
                  <button onClick={nextImage} className="bg-orange-300 p-2 rounded-full">Next</button>
                </div>
              )}
              {/* <button className="mt-4 text-orange-500 underline">Click here to upload</button> */}
              <div className="mt-4 space-y-2">
                <button onClick={()=>navigate('/multi-form-profile',{state:"update"})} className="block w-full bg-orange-500 text-white py-1 md:py-2 rounded-md">Edit Personal Profile</button>
                {/* <button className="block w-full bg-gray-200 py-1 md:py-2 rounded-md">Add Photos</button> */}
              </div>
            </div>

            {/* Profile Details */}
            <div className="md:w-2/3 px-4">
              <h2 className="text-lg md:text-xl font-bold">{displayValue(userData?.name, 'Name Not Provided')}</h2>
              <p className="text-gray-600 text-sm md:text-base">Phone: {userData?.phone_no}</p>
              <p className="text-gray-600 text-sm md:text-base">Gender: {displayValue(userData?.gender==='M'?'Male':'Female', 'Not Specified')}</p>
              <p className="text-gray-600 text-sm md:text-base">Location: {displayValue(userData?.location)}</p>
              <p className="text-gray-600 text-sm md:text-base">Religion: {displayValue(userData?.religion)}</p>
              <p className="text-gray-600 text-sm md:text-base">Income: ₹{userData?.income??'Not Disclosed'}</p>
              <div className="mt-4 flex flex-col md:flex-row md:space-x-4">
                {/* <button className="text-orange-500 underline mb-2 md:mb-0">Edit Partner Profile</button>
                <button className="text-orange-500 underline">Edit Contact Details</button> */}
              </div>
            </div>
          </div>
        </div>

        {/* About Myself Section */}
        <div className="bg-white shadow-md rounded-lg p-4 mt-6">
          <div className="border-b pb-2 mb-4">
            <h3 className="text-lg font-bold">About Myself</h3>
            {/* <button className="text-orange-500 underline float-right">Edit</button> */}
          </div>
          <p className="text-sm md:text-base">
            {displayValue(userData?.bio, 'Bio not provided yet.')}
          </p>
        </div>

        {/* Basic Information Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
          <div className="bg-white shadow-md rounded-lg p-4">
            <div className="border-b pb-2 mb-4">
              <h3 className="text-lg font-bold">Basics & Lifestyle</h3>
              {/* <button className="text-orange-500 underline float-right">Edit</button> */}
            </div>
            <ul className="space-y-2 text-sm md:text-base">
              <li>Date of Birth: {displayValue(userData?.date_of_birth, 'Not Provided')}</li>
              <li>Height: {userData?.height ? `${userData.height} cm` : 'Not Specified'}</li>
              <li>Job: {userData?.job ? userData?.job_about || 'Job Information' : 'No Job'}</li>
              <li>Business: {userData?.buiness ? userData?.buiness_about || 'Business Details' : 'No Business'}</li>
            </ul>
          </div>

          <div className="bg-white shadow-md rounded-lg p-4">
            <div className="border-b pb-2 mb-4">
              <h3 className="text-lg font-bold">Habits</h3>
              {/* <button className="text-orange-500 underline float-right">Edit</button> */}
            </div>
            <ul className="space-y-2 text-sm md:text-base">
              <li>Smoking: {userData?.smoke}</li>
              <li>Drinking: {userData?.drink}</li>
            </ul>
          </div>
        </div>

        {/* Logout Button */}
        <div className="text-center mt-8">
          <button
            onClick={handleLogout}
            className="bg-red-500 text-white px-4 py-2 rounded-md"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
