import React from 'react';

const ParentInvolved = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">The Role of Parents in Matrimony</h1>
      <p className="text-gray-700 mb-4">
        In the journey of finding the right partner, the involvement of parents plays a pivotal role. 
        Their guidance, support, and understanding can make the matrimony process smoother and more fulfilling.
      </p>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Why Parents Matter</h2>
      <ul className="list-disc ml-5 mt-4">
        <li className="mb-2">
          <strong>Experience and Wisdom:</strong> Parents bring their life experiences to help guide their children in making important decisions.
        </li>
        <li className="mb-2">
          <strong>Emotional Support:</strong> The journey to finding a partner can be emotionally taxing. Parents provide the necessary support and encouragement.
        </li>
        <li className="mb-2">
          <strong>Understanding Family Values:</strong> Parents often help to instill values and traditions that are important when choosing a partner.
        </li>
        <li className="mb-2">
          <strong>Network and Connections:</strong> Parents can leverage their networks to introduce their children to potential matches.
        </li>
        <li className="mb-2">
          <strong>Facilitating Communication:</strong> Parents often act as intermediaries, helping to facilitate discussions between families.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-6 mb-4">Tips for Parents</h2>
      <p className="text-gray-700 mb-4">
        Here are some tips for parents to ensure a positive and supportive role in the matrimony journey:
      </p>
      <ul className="list-disc ml-5 mt-4">
        <li className="mb-2">
          <strong>Be Open-Minded:</strong> Encourage your child to explore different options and perspectives.
        </li>
        <li className="mb-2">
          <strong>Communicate:</strong> Maintain open lines of communication with your child about their preferences and feelings.
        </li>
        <li className="mb-2">
          <strong>Respect Their Choices:</strong> While guidance is important, it's crucial to respect your child's choices and decisions.
        </li>
        <li className="mb-2">
          <strong>Educate Yourself:</strong> Stay informed about the current trends and practices in matrimony to provide relevant advice.
        </li>
        <li className="mb-2">
          <strong>Encourage Patience:</strong> Finding the right partner takes time; support your child throughout the process.
        </li>
      </ul>
    </div>
  );
};

export default ParentInvolved;
