import React from 'react'
import { Link } from 'react-router-dom'

export default function Error404() {
  return (
	<div className="container flex items-center justify-center min-h-screen">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-orange-500">404</h1>
        <p className="text-2xl font-semibold text-orange-500 mt-4">
          Oops! Page not found.
        </p>
        <p className="text-lg text-gray-800 mt-2">
          Sorry, we couldn't find the page you're looking for.
        </p>
        <Link to="/">
          <button className="mt-6 px-6 py-3 text-white bg-orange-500 hover:bg-orange-600 rounded-lg">
            Go back home
          </button>
        </Link>
      </div>
    </div>
  )
}
