import React from "react";

export default function MainButton({ title, type, onClick,isDisabled }) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={isDisabled}
      class={`w-full text-white ${isDisabled?'bg-orange-300':'bg-orange-400 hover:bg-orange-500'}  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center capitalize transition-all duration-200 ease-in-out `}
    >
      {title}
    </button>
  );
}
