
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import './App.css';

import Frontend from './components/Frontend';
import Signup from './components/Signup';
import Login from './components/Login';
import Navbar from './components/fcomponents/Navbar';
import Footer from './components/fcomponents/Footer';
import Register from './components/Register';
import { useSelector } from 'react-redux';
import Matches from './components/Matches';
import MatchProfile from './components/MatchProfile';
import MultiStepForm from './components/fcomponents/MultiStepForm';
import SwipeComponent from './components/fcomponents/SwipeComponent';
import SubscriptionPage from './components/SubscriptionPage';
import { Toaster } from 'sonner';
import Error404 from './components/fcomponents/Error404';
import ProfilePage from './components/Profile';
import PreferedForm from './components/fcomponents/PreferedForm';
import Contact from './components/Contact';
import Matrimony101 from './components/Matrimony101';
import { useEffect } from 'react';
import AboutUs from './components/AboutUs';
import HelpfulTips from './components/HelpfulTips';
import PrivacyAndYou from './components/PrivacyAndYou';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import TopRankingCities from './components/TopRankingCities';
import ParentInvolved from './components/ParentInvolved';

function App() {
  const {pathname}=useLocation();

  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },[pathname])
  const user=useSelector(state=>state.user.isLoggedIn);

 const sampleData=[
  {
    "name": "Emma Rose",
    "age": 25,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Product  Designer",
    "company": "ABC Pvt. Ltd.",
    "education": "B.Des., ABC Design School",
    "location": "Bangalore",
    "about": "I love photography with a passion in design and music, along with a deep interest in astrophysics. ANIMATION & GRAPHIC DESIGN are the areas which fascinate me the most!!! All interests of mine, what I believe, helps me to relax from my otherwise monotonous schedule!"
  },
  {
    "name": "John Smith",
    "age": 29,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Software Engineer",
    "company": "Tech  Solutions Inc.",
    "education": "B.Sc. in Computer Science, XYZ University",
    "location": "New York",
    "about": "Avid coder with a love for solving complex problems. In my free time, I enjoy hiking and exploring new places."
  },
  {
    "name": "Sophia Wilson",
    "age": 24,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Graphic Designer",
    "company": "Design Studio",
    "education": "B.A. in Fine Arts, Creative University",
    "location": "Los Angeles",
    "about": "Passionate about creating visually appealing designs. Love coffee, art, and spending weekends painting."
  },
  {
    "name": "Liam Johnson",
    "age": 32,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Marketing Manager",
    "company": "Marketing Hub",
    "education": "MBA, Business School",
    "location": "Chicago",
    "about": "Driven by marketing strategies and helping brands grow. A football enthusiast and tech geek."
  },
  {
    "name": "Olivia Davis",
    "age": 27,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "UI/UX Designer",
    "company": "Creative Minds",
    "education": "M.Des., Design University",
    "location": "San Francisco",
    "about": "UI/UX designer passionate about creating intuitive user experiences. In love with photography and traveling."
  },
  {
    "name": "Ethan Brown",
    "age": 28,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Full Stack Developer",
    "company": "WebTech Solutions",
    "education": "B.Tech in Computer Science, Web University",
    "location": "Seattle",
    "about": "I enjoy developing web apps and building scalable systems. In my free time, I love working out and playing chess."
  },
  {
    "name": "Isabella Martinez",
    "age": 26,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Fashion Designer",
    "company": "Trendy Styles",
    "education": "Diploma in Fashion Design, Fashion Institute",
    "location": "Miami",
    "about": "Fashion enthusiast with a keen eye for modern trends. I enjoy traveling and sketching new designs."
  },
  {
    "name": "James Anderson",
    "age": 31,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Finance Analyst",
    "company": "FinTech Corp",
    "education": "MBA in Finance, XYZ University",
    "location": "Boston",
    "about": "Finance expert with a passion for stock market analysis. I enjoy running marathons and reading."
  },
  {
    "name": "Ava Thompson",
    "age": 23,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Content Writer",
    "company": "Digital Ink",
    "education": "B.A. in English, Wordsmith College",
    "location": "Austin",
    "about": "Storyteller at heart. I love crafting engaging content and finding creative ways to tell stories. A coffee addict."
  },
  {
    "name": "Noah Harris",
    "age": 30,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Project Manager",
    "company": "BuildRight Corp.",
    "education": "M.B.A., Business School",
    "location": "Denver",
    "about": "Experienced project manager with a focus on construction and development. A traveler and mountain climber in free time."
  },
  {
    "name": "Mia Walker",
    "age": 28,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "HR Manager",
    "company": "People Matters",
    "education": "BBA in Human Resources, State University",
    "location": "Houston",
    "about": "Passionate about people management. I love baking and organizing events during the weekends."
  },
  {
    "name": "Elijah Moore",
    "age": 29,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Cybersecurity Analyst",
    "company": "SecureNet",
    "education": "B.Tech in IT, Tech Institute",
    "location": "San Diego",
    "about": "Cybersecurity specialist with a knack for finding vulnerabilities. A sci-fi movie buff and car enthusiast."
  },
  {
    "name": "Charlotte Lee",
    "age": 26,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Architect",
    "company": "Design Build Inc.",
    "education": "M.Arch., Architecture University",
    "location": "Philadelphia",
    "about": "I am passionate about designing sustainable buildings. My hobbies include pottery and visiting art galleries."
  },
  {
    "name": "William King",
    "age": 34,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Lawyer",
    "company": "Law Firm LLC",
    "education": "J.D., Law School",
    "location": "Washington D.C.",
    "about": "Experienced attorney with a focus on corporate law. In my free time, I enjoy playing golf and reading legal thrillers."
  },
  {
    "name": "Amelia Clark",
    "age": 27,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Interior Designer",
    "company": "HomeSpace Design",
    "education": "B.Des., Interior Design School",
    "location": "Dallas",
    "about": "Creating beautiful and functional spaces is my passion. I also love cooking and experimenting with new recipes."
  },
  {
    "name": "Henry Scott",
    "age": 31,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Data Scientist",
    "company": "DataLab Inc.",
    "education": "M.Sc. in Data Science, Data University",
    "location": "Los Angeles",
    "about": "I enjoy analyzing data and finding insights. In my free time, I enjoy surfing and learning new programming languages."
  },
  {
    "name": "Emily Young",
    "age": 24,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Social Media Manager",
    "company": "Buzz Inc.",
    "education": "BBA in Marketing, XYZ University",
    "location": "San Jose",
    "about": "Social media enthusiast with a love for digital marketing. I enjoy photography and creating viral content."
  },
  {
    "name": "Alexander White",
    "age": 33,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Entrepreneur",
    "company": "StartUp Hub",
    "education": "MBA in Entrepreneurship, Business School",
    "location": "Portland",
    "about": "Founder of multiple tech startups. I love mentoring young entrepreneurs and exploring new technologies."
  },
  {
    "name": "Abigail Hall",
    "age": 22,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Freelance Writer",
    "company": "Self-Employed",
    "education": "B.A. in Literature, Creative University",
    "location": "Phoenix",
    "about": "Freelance writer with a love for storytelling. When I'm not writing, I'm baking or hiking in the mountains."
  },
  {
    "name": "Daniel Wright",
    "age": 27,
    "profileImage": "https://placehold.co/1920x1080",
    "jobTitle": "Mechanical Engineer",
    "company": "Engineered Solutions",
    "education": "B.E. in Mechanical Engineering, Engineering Institute",
    "location": "Detroit",
    "about": "Mechanical engineer with a passion for cars and machines. I enjoy restoring classic cars in my free time."
  }
]

const userData = {
  id: 3,
  name: "Ashish",
  phone_no: "1234567890",
  is_verified: false,
  gender: "M",
  height: 210,
  location: "Kolkata",
  religion: "Christianity",
  income: 2000,
  bio: "what bio",
  family_background: "Good",
  photo1: "/media/Capture1.PNG",
  photo2: "/media/Capture1_2kZQ9kh.PNG",
  photo3: "/media/Capture1_WQK9dE8.PNG",
  photo4: "/media/Capture1_pvI5AVI.PNG",
  photo5: "/media/Capture1_rOb6Jdg.PNG",
  date_of_birth: "2024-09-24",
  job: false,
  smoke: false,
  drink: false,
  job_about: null,
  buiness_about: null,
  buiness: false,
};


  return (
    <>
      <Navbar user={user}/>
        <div className=''>
          <Routes>
            <Route path="/" element={<Frontend  title="Second Inning Matrimony" />} />
            <Route path="*" element={<Error404/>} />
            <Route path="/signup" element={!user?<Signup />:<Navigate to={'/'}/>} />
            <Route path="/register" element={!user?<Register />:<Navigate to={'/'}/>} />
            <Route path="/login" element={!user?<Login />:<Navigate to={'/'}/>} />

            <Route path="/matrimony-101" element={<Matrimony101 />} />
            <Route path="/about-us" element={<AboutUs/>} />
            <Route path="/helpful-tips" element={<HelpfulTips/>} />
            <Route path="/privacy" element={<PrivacyAndYou/>} />
            <Route path="/terms-of-use" element={<TermsOfUse/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/top-ranking-cities" element={<TopRankingCities/>} />
            <Route path="/parent-involved" element={<ParentInvolved/>} />

            {/* <Route path="/forget-password" element={<Forget />} />
            <Route path="/reset-password" element={<Reset />} />
            <Route path="/portfolio" element={<Portfolio />} /> */}

            <Route path="/profile" element={user?<ProfilePage user={userData}/>:<Navigate to={'/login'}/>} />
            <Route path="/prefered" element={user?<PreferedForm user={userData}/>:<Navigate to={'/login'}/>} />
            <Route path="/matches" element={user?<Matches matchData={sampleData}/>:<Navigate to={'/login'}/>} />
            <Route path="/match/profile" element={user?<MatchProfile />:<Navigate to={'/login'}/>} />
            <Route path="/multi-form-profile" element={user?<MultiStepForm />:<Navigate to={'/login'}/>} />
            <Route path="/swipe" element={user?<SwipeComponent  cardData={sampleData}/>:<Navigate to={'/login'}/>} />
            <Route path="/subscription" element={user?<SubscriptionPage/>:<Navigate to={'/login'}/>} />
            <Route path="/contact" element={user?<Contact/>:<Navigate to={'/login'}/>} />
          </Routes>
        </div>
      <Footer/>
      <Toaster
        position="top-right"
        toastOptions={{
          style: { 
            background: '#FFA500',
            color:'white'
          },
        }}
      />
    </>
  );
}

export default App;
