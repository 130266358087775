import React from 'react';

const SubscriptionCard = ({ plan,isCurrentPlan }) => {
  const { model, price, description } = plan;

  return (
    <div className={`w-full 2xl:w-[420px] h-[35rem] bg-orange-50 flex items-center justify-between flex-col rounded-lg shadow-md  divide-y ${isCurrentPlan ? 'border border-orange-500' : ''}`}>
      <div className="w-full pt-4 px-6 pb-6">
        <div className="flex justify-end">
          <div className="bg-orange-300 rounded-full flex justify-center items-center px-3 py-1">
            <p className="text-[#00153B] text-sm font-bold">
              {model}
            </p>
          </div>
        </div>

        <div className="w-full text-center mt-4">
          <p className="text-[#00153B] text-lg font-bold">
            {model}
          </p>
          <p className="text-[#00153B] text-4xl font-bold">
            ₹{price}
          </p>
        </div>

        <div className="w-full text-center mt-4">
            <pre dangerouslySetInnerHTML={{__html:description}} className="text-[#717F87] text-left text-lg font-medium">
            </pre>
        </div>
      </div>

      <div className="w-full pt-6 px-6 pb-6">
        <div className="w-full mt-6 text-center">
          <button className={`rounded-lg py-2 px-4 text-white text-sm font-semibold ${isCurrentPlan ? 'bg-[#E1E3E5]' : 'bg-orange-500'}`}>
            {isCurrentPlan==null?isCurrentPlan ? 'Current Plan' : 'Update':'Select Plan'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
