import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-10">
      <h1 className="text-3xl font-bold mb-6">About Us</h1>
      <p className="text-gray-700 mb-4">
        At Second Inning Matrimony, we believe in the power of love and companionship. 
        Our journey began with a simple mission: to help individuals find their perfect match 
        and embark on a fulfilling relationship. We understand that every person is unique, 
        and we are committed to providing a personalized experience that meets your individual needs.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
      <p className="text-gray-700 mb-4">
        Our mission is to connect people from all walks of life, allowing them to explore 
        meaningful relationships. We aim to create a safe, supportive, and welcoming environment 
        for individuals looking for love, friendship, or companionship.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Our Values</h2>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Integrity: We uphold honesty and transparency in all our interactions.</li>
        <li>Respect: We value the dignity of each individual and foster a respectful community.</li>
        <li>Support: Our dedicated team is here to assist you at every step of your journey.</li>
        <li>Inclusivity: We embrace diversity and aim to connect people from different backgrounds.</li>
      </ul>
      <h2 className="text-2xl font-semibold mb-4">Meet Our Team</h2>
      <p className="text-gray-700 mb-4">
        Our team consists of experienced professionals who are passionate about helping others 
        find love. With a wealth of knowledge in matchmaking and relationship counseling, we 
        are here to guide you through the process of finding your ideal partner.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Why Choose Us?</h2>
      <p className="text-gray-700 mb-4">
        Choosing Second Inning Matrimony means choosing a partner who genuinely cares about your happiness. 
        We leverage technology and human intuition to create meaningful matches that resonate with you. 
        Let us help you find your second chance at love.
      </p>
      <h2 className="text-2xl font-semibold mb-4">Get in Touch</h2>
      <p className="text-gray-700 mb-4">
        If you have any questions or would like to learn more about our services, feel free to 
        <a href="mailto:secondinningmatrimonial@gmail.com" className="text-blue-500 hover:underline"> secondinningmatrimonial@gmail.com</a>. 
        We are here to assist you on your journey to finding love.
      </p>
    </div>
  );
};

export default AboutUs;
