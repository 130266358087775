import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import axiosInstance from '../utils/Axios';
import { toast } from 'sonner';

// Validation schema
const schema = z.object({
  name: z.string().min(3, "Name must be at least 3 characters"),
  email: z.string().email("Please enter a valid email address"),
  phone_no: z.string().min(10, "Phone number must be at least 10 digits"),
  content: z.string().min(10, "Content must be at least 10 characters"),
});

const Contact = () => {
	const [loading,setLoading]=useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data) => {
    axiosInstance.post('/contact/contact/',data)
		.then(res=>{
			toast.success('Message sent successfully')
		})
		.catch(err=>{
			console.log(err)
		})
  };

  return (
    <section className="bg-white">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900">
          Contact Us
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 sm:text-xl">
          Got a question or want to get in touch? Let us know.
        </p>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          {/* Name Field */}
          <div>
            <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
              Your Name
            </label>
            <input
              type="text"
              id="name"
              {...register("name")}
              className={`shadow-sm bg-gray-50 border ${
                errors.name ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
              placeholder="John Doe"
              required
            />
            {errors.name && (
              <p className="text-red-500 text-sm mt-1">{errors.name.message}</p>
            )}
          </div>

          {/* Email Field */}
          <div>
            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
              Your Email
            </label>
            <input
              type="email"
              id="email"
              {...register("email")}
              className={`shadow-sm bg-gray-50 border ${
                errors.email ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
              placeholder="name@example.com"
              required
            />
            {errors.email && (
              <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
            )}
          </div>

          {/* Phone Number Field */}
          <div>
            <label htmlFor="phone_no" className="block mb-2 text-sm font-medium text-gray-900">
              Your Phone Number
            </label>
            <input
              type="text"
              id="phone_no"
              {...register("phone_no")}
              className={`shadow-sm bg-gray-50 border ${
                errors.phone_no ? "border-red-500" : "border-gray-300"
              } text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5`}
              placeholder="1234567890"
              required
            />
            {errors.phone_no && (
              <p className="text-red-500 text-sm mt-1">{errors.phone_no.message}</p>
            )}
          </div>

          {/* Content Field */}
          <div>
            <label htmlFor="content" className="block mb-2 text-sm font-medium text-gray-900">
              Your Message
            </label>
            <textarea
              id="content"
              rows="6"
              {...register("content")}
              className={`block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border ${
                errors.content ? "border-red-500" : "border-gray-300"
              } focus:ring-primary-500 focus:border-primary-500`}
              placeholder="Leave a comment..."
              required
            ></textarea>
            {errors.content && (
              <p className="text-red-500 text-sm mt-1">{errors.content.message}</p>
            )}
          </div>

          {/* Submit Button */}
          <button
            type="submit"
			disabled={loading}
            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-orange-500 hover:bg-orange-600 focus:ring-4 focus:outline-none focus:ring-blue-300"
          >
            {loading?'Loading':'Send Message'}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
