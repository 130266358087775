import React from 'react';
import { Controller } from 'react-hook-form';
import SingleInput from './SingleInput';


const FormInput  = ({ label,className,control,name,type,isDisabled,placeholder,isRequired,maxLength,onInput }) => {
  return (
    <Controller
		control={control}
		name={name}
		render={({field: {value, onChange, onBlur},fieldState:{error}}) => (
		<div className='w-full flex flex-col'>
		<SingleInput
			label={label}
			className={className}
			onChange={onChange}
			onBlur={onBlur}
			value={value}
			type={type}
			isDisabled={isDisabled}
			placeholder={placeholder}
			isRequired={isRequired}
			maxLength={maxLength}
			onInput={onInput}
		/>
		{error&&<p className='text-red-600 text-md text-sm'>{error.message}</p>}
		</div>
		)}
	/>
  );
}

export default FormInput;
