import React, { useEffect, useState } from 'react'
import ProfileCard from './fcomponents/ProfileCard'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../utils/Axios';
import Advertisment from './fcomponents/Advertisment';

export default function Matches() {

	const navigate=useNavigate();
	const [data,setData]=useState([])

	useEffect(()=>{
		axiosInstance.get('/show-profile/matched-profiles/')
			.then(res=>{
				setData(res?.data)
			})
			.catch(err=>{
				console.log(err?.response?.data)
			})
	},[])

  return (
	<div className='container mx-auto md:p-4 p-2'>
		<h1 className='text-center font-semibold md:text-5xl text-3xl my-2.5 md:my-5 text-orange-500'>Matches</h1>
		<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
			{data.length>0&&data.map((item,index)=>(
				<ProfileCard key={index} data={item} onClick={()=>navigate('/match/profile',{state:item?.id})}/>
			))}
		</div>
		<Advertisment/>
	</div>
  )
}
