import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../utils/Axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../app/User";

const MultiStepForm = () => {
  const navigate = useNavigate();
  const dispatch=useDispatch();
  const { state } = useLocation();

  const [step, setStep] = useState(1); // Step tracking
  const [loading, setLoading] = useState(false);
  const userProfile=useSelector(state=>state.user.userProfile);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    gender: "",
    height: "",
    location: "",
    religion: "",
    income: "",
    family_background: "",
    family_type: "",
    date_of_birth: "",
    job: false,
    about_job: "",
    business: false,
    about_business: "",
    smoke: "",
    drink: "",
    bio: "",
    profession: "",
    cast: "",
    sub_cast: "",
    medical_history:'',
    medical_history_if_any:"",
    images: [], // For storing uploaded images
  });
  const [errors, setErrors] = useState({}); // For validation errors

  useEffect(() => {
    if(state === "update" || !userProfile?.is_profile_created){
      axiosInstance
        .get("/account/user-data/")
        .then((res) => {
          setFormData({
            name: res.data.name,
            email: res.data.email,
            gender: res.data.gender,
            height: res.data.height,
            location: res.data.location,
            religion: res.data.religion,
            income: res.data.income,
            family_background: res.data.family_background,
            family_type: res.data.family_type,
            date_of_birth: res.data.date_of_birth,
            job: res.data.job,
            about_job: res.data.job_about,
            business: res.data.business,
            about_business: res.data.business_about,
            smoke: res.data.smoke,
            drink: res.data.drink,
            bio: res.data.bio,
            profession: res.data.job?'job':'business',
            cast: res.data.cast,
            sub_cast: res.data.sub_cast,
            medical_history:res.data.medical_history?'Yes':'No',
            medical_history_if_any:res.data.medical_history_if_any,
            images: [],
          });
        })
        .catch((err) => {
          console.log(err.response.log);
        });
    }else{
      navigate("/profile");
    }
  }, []);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validate the fields based on the current step
  const validateStep = () => {
    let newErrors = {};
    switch (step) {
      case 1:
        if (!formData.name) newErrors.name = "Name is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.gender) newErrors.gender = "Gender is required";
        if (!formData.date_of_birth)
          newErrors.date_of_birth = "Date of Birth is required";
        if (!formData.height) newErrors.height = "Height is required";
        if (!formData.location) newErrors.location = "Location is required";
        if (!formData.religion) newErrors.religion = "Religion is required";
        break;
      case 2:
        if (!formData.cast) newErrors.cast = "Cast is required";
        if (!formData.sub_cast) newErrors.sub_cast = "Sub Cast is required";
        if (!formData.family_background)
          newErrors.family_background = "Family background is required";
        if (!formData.family_type)
          newErrors.family_type = "Family type is required";
        break;
      case 3:
        if (!formData.profession)
          newErrors.profession = "Profession is required";
        if (!formData.income) newErrors.income = "Income is required";
        if (formData.profession === "job" && !formData.about_job)
          newErrors.about_job = "Job details are required";
        if (formData.profession === "business" && !formData.about_business)
          newErrors.about_business = "Business details are required";
        break;
      case 4:
        if (!formData.smoke) newErrors.smoke = "Smoke preference is required";
        if (!formData.drink) newErrors.drink = "Drink preference is required";
        if (!formData.medical_history) newErrors.medical_history = "Medical history is required";
        if (formData.medical_history==='Yes' && !formData.medical_history_if_any) newErrors.medical_history_if_any = "Medical history info is required";
        break;
      case 5:
        if (!formData.bio) newErrors.bio = "Bio is required";
        break;
      case 6:
        if (formData.images.length < 1)
          newErrors.images = "At least one image is required";
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Navigate to next step
  const handleNext = () => {
    if (validateStep()) {
      setStep(step + 1);
    }
  };

  // Navigate to previous step
  const handlePrevious = () => setStep(step - 1);

  // Handle form submission
  const handleSubmit = () => {
    let data = new FormData();

    // Append non-file fields
    data.append("name", formData.name);
    data.append("email", formData.email);
    data.append("gender", formData.gender);
    data.append("date_of_birth", formData.date_of_birth);
    data.append("height", formData.height);
    data.append("location", formData.location);
    data.append("religion", formData.religion);
    data.append("family_background", formData.family_background);
    data.append("family_type", formData.family_type);
    data.append("profession", formData.profession);
    data.append("income", formData.income);
    data.append("smoke", formData.smoke);
    data.append("drink", formData.drink);
    data.append("bio", formData.bio);
    data.append("cast", formData.cast);
    data.append("sub_cast", formData.sub_cast);
    data.append('is_profile_created',true);

    // Add profession type-specific fields
    if (formData.profession === "job") {
      data.append("job", true);
      data.append("job_about", formData.about_job || "");
    } else {
      data.append("business", true);
      data.append("business_about", formData.about_business || "");
    }

    if(formData.medical_history==='Yes'){
      data.append("medical_history",true);
      data.append("medical_history_if_any", formData.medical_history_if_any);
    }else{
      data.append("medical_history", false);
    }

    // Append image files if they exist
    if (formData.images[0]) data.append("photo1", formData.images[0]);
    if (formData.images[1]) data.append("photo2", formData.images[1]);
    if (formData.images[2]) data.append("photo3", formData.images[2]);
    if (formData.images[3]) data.append("photo4", formData.images[3]);
    if (formData.images[4]) data.append("photo5", formData.images[4]);

    setLoading(true);
    axiosInstance
      .patch("/account/update-profile/", data, {
        headers: {
          "Content-Type": "multipart/form-data", // Required for uploading files
        },
      })
      .then((res) => {
        setLoading(false);
        localStorage.setItem('user',JSON.stringify(res.data))
        dispatch(userLogin(res.data))
        navigate("/");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  // Handle image uploads
  const handleImageChange = (e, index) => {
    const files = e.target.files;
    if (files && files[0]) {
      const newImages = [...formData.images];
      newImages[index] = files[0]; // Set image at the specific index
      setFormData({ ...formData, images: newImages });
    }
  };

  return (
    <div className="max-w-2xl min-h-screen flex items-center justify-start flex-col mx-auto p-6 space-y-6">
      {/* Step Progress */}
      <div className="flex justify-between mb-4 w-full">
        {[
          "Personal",
          "Family",
          "Professional",
          "Lifestyle",
          "Bio",
          "Upload Images",
        ].map((label, index) => (
          <div
            key={index}
            className={`flex-1 text-center py-2 md:text-base text-sm mx-2 ${
              step === index + 1
                ? "font-bold border-b-2 border-orange-500"
                : "text-gray-500"
            }`}
          >
            {label}
          </div>
        ))}
      </div>

      {/* Conditional Rendering */}
      {step === 1 && (
        <Step1
          formData={formData}
          handleChange={handleChange}
          onNext={handleNext}
          errors={errors}
        />
      )}
      {step === 2 && (
        <Step2
          formData={formData}
          handleChange={handleChange}
          onNext={handleNext}
          onPrevious={handlePrevious}
          errors={errors}
        />
      )}
      {step === 3 && (
        <Step3
          formData={formData}
          handleChange={handleChange}
          onNext={handleNext}
          onPrevious={handlePrevious}
          errors={errors}
        />
      )}
      {step === 4 && (
        <Step4
          formData={formData}
          handleChange={handleChange}
          onNext={handleNext}
          onPrevious={handlePrevious}
          errors={errors}
        />
      )}
      {step === 5 && (
        <Step5
          formData={formData}
          handleChange={handleChange}
          onNext={handleNext}
          onPrevious={handlePrevious}
          errors={errors}
          loading={loading}
        />
      )}
      {step === 6 && (
        <Step6
          formData={formData}
          handleChange={handleImageChange}
          onSubmit={handleSubmit}
          onPrevious={handlePrevious}
          errors={errors}
          loading={loading}
        />
      )}
    </div>
  );
};

// Step 1 - Personal Details
const Step1 = ({ formData, handleChange, onNext, errors }) => {
  const ref = useRef();
  return (
    <div className="space-y-4 w-full">
      <h2 className="text-lg font-semibold">Personal Details</h2>
      <input
        type="text"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Name"
        className="border p-2 rounded w-full"
      />
      {errors.name && <p className="text-red-500">{errors.name}</p>}

      <input
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        placeholder="Email"
        className="border p-2 rounded w-full"
      />
      {errors.email && <p className="text-red-500">{errors.email}</p>}



      <select
        name="gender"
        value={formData.gender}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select Gender</option>
        <option value="M">Male</option>
        <option value="F">Female</option>
      </select>
      {errors.gender && <p className="text-red-500">{errors.gender}</p>}

      <input
        type="text"
        name="date_of_birth"
        value={formData.date_of_birth}
        onChange={handleChange}
        ref={ref}
        onFocus={() => (ref.current.type = "date")}
        onBlur={() => (ref.current.type = "date")}
        placeholder="DD/MM/YYYY"
        className="border p-2 rounded w-full"
      />
      {errors.date_of_birth && (
        <p className="text-red-500">{errors.date_of_birth}</p>
      )}

      <input
        type="text"
        name="height"
        value={formData.height}
        onChange={handleChange}
        placeholder="Height (cm)"
        className="border p-2 rounded w-full"
      />
      {errors.height && <p className="text-red-500">{errors.height}</p>}

      <input
        type="text"
        name="location"
        value={formData.location}
        onChange={handleChange}
        placeholder="Location"
        className="border p-2 rounded w-full"
      />
      {errors.location && <p className="text-red-500">{errors.location}</p>}

      <select
        name="religion"
        value={formData.religion}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select Religion</option>
        <option value="Islam">Islam</option>
        <option value="Christianity">Christianity</option>
        <option value="Hinduism">Hinduism</option>
        <option value="Buddhism">Buddhism</option>
        <option value="Sikhism">Sikhism</option>
        <option value="Judaism">Judaism</option>
        <option value="Atheism">Atheism</option>
        <option value="Agnosticism">Agnosticism</option>
        <option value="Other">Other</option>
      </select>
      {errors.religion && <p className="text-red-500">{errors.religion}</p>}
      <button
        onClick={onNext}
        className="bg-orange-500 text-white py-2 px-4 rounded"
      >
        Next
      </button>
    </div>
  );
};

// Step 2 - Family Details
const Step2 = ({ formData, handleChange, onNext, onPrevious, errors }) => {
  return (
    <div className="space-y-4 w-full">
      <input
        type="text"
        name="cast"
        value={formData.cast}
        onChange={handleChange}
        placeholder="Cast"
        className="border p-2 rounded w-full"
      />
      {errors.cast && <p className="text-red-500">{errors.cast}</p>}

      <input
        type="text"
        name="sub_cast"
        value={formData.sub_cast}
        onChange={handleChange}
        placeholder="Sub Cast"
        className="border p-2 rounded w-full"
      />
      {errors.sub_cast && <p className="text-red-500">{errors.sub_cast}</p>}

      <h2 className="text-lg font-semibold">Family Details</h2>
      <input
        type="text"
        name="family_background"
        value={formData.family_background}
        onChange={handleChange}
        placeholder="Family Background"
        className="border p-2 rounded w-full"
      />
      {errors.family_background && (
        <p className="text-red-500">{errors.family_background}</p>
      )}

      <select
        name="family_type"
        value={formData.family_type}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select Family Type</option>
        <option value="Nuclear Family">Nuclear Family</option>
        <option value="Joint Family">Joint Family</option>
        <option value="UpperMiddle Class Family">
          Upper Middle Class Family
        </option>
        <option value="Middle Family">Middle Class Family</option>
        <option value="Affluent Family">Affluent Family</option>
        <option value="Business Family">Business Family</option>
        <option value="Liberal Family">Liberal Family</option>
        <option value="Conservative Family">Conservative Family</option>
        <option value="Religious Family">Religious Family</option>
        <option value="Rich Family">Rich Family</option>
        <option value="Moderate Family">Moderate Family</option>
      </select>
      {errors.family_type && (
        <p className="text-red-500">{errors.family_type}</p>
      )}

      <div className="flex justify-between">
        <button
          onClick={onPrevious}
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={onNext}
          className="bg-orange-500 text-white py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

// Step 3 - Professional Details
const Step3 = ({ formData, handleChange, onNext, onPrevious, errors }) => {
  return (
    <div className="space-y-4 w-full">
      <h2 className="text-lg font-semibold">Professional Details</h2>
      <select
        name="profession"
        value={formData.profession}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select Profession Type</option>
        <option value="job">Job</option>
        <option value="business">Business</option>
      </select>
      {errors.profession && <p className="text-red-500">{errors.profession}</p>}

      <input
        type="number"
        name="income"
        value={formData.income}
        onChange={handleChange}
        placeholder="Income"
        className="border p-2 rounded w-full"
      />
      {errors.income && <p className="text-red-500">{errors.income}</p>}

      {formData.profession === "job" && (
        <textarea
          name="about_job"
          value={formData.about_job}
          onChange={handleChange}
          placeholder="About Job"
          className="border p-2 rounded w-full"
        />
      )}
      {errors.about_job && <p className="text-red-500">{errors.about_job}</p>}

      {formData.profession === "business" && (
        <textarea
          name="about_business"
          value={formData.about_business}
          onChange={handleChange}
          placeholder="About Business"
          className="border p-2 rounded w-full"
        />
      )}
      {errors.about_business && (
        <p className="text-red-500">{errors.about_business}</p>
      )}

      <div className="flex justify-between">
        <button
          onClick={onPrevious}
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={onNext}
          className="bg-orange-500 text-white py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

// Step 4 - Lifestyle Choices
const Step4 = ({ formData, handleChange, onNext, onPrevious, errors }) => {
  return (
    <div className="space-y-4 w-full">
      <h2 className="text-lg font-semibold">Lifestyle Choices</h2>
      <label className="block">Smoke</label>
      <select
        name="smoke"
        value={formData.smoke}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select</option>
        <option value="occasionally">Occasionally</option>
        <option value="Regular">Regular</option>
        <option value="No">No</option>
      </select>
      {errors.smoke && <p className="text-red-500">{errors.smoke}</p>}

      <label className="block">Drink</label>
      <select
        name="drink"
        value={formData.drink}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select</option>
        <option value="occasionally">Occasionally</option>
        <option value="Regular">Regular</option>
        <option value="No">No</option>
      </select>
      {errors.drink && <p className="text-red-500">{errors.drink}</p>}
      <h1>Any Medical History</h1>
      <select
        name="medical_history"
        value={formData.medical_history}
        onChange={handleChange}
        className="border p-2 rounded w-full"
      >
        <option value="">Select</option>
        <option value="Yes">Yes</option>
        <option value="No">No</option>
      </select>
      {errors.medical_history && <p className="text-red-500">{errors.medical_history}</p>}
      {formData.medical_history === 'Yes' && (
        <textarea
          name="medical_history_if_any"
          value={formData.medical_history_if_any}
          onChange={handleChange}
          placeholder="More info about medical history"
          className="border p-2 rounded w-full"
        />
      )}
      {errors.medical_history_if_any && <p className="text-red-500">{errors.medical_history_if_any}</p>}
      <div className="flex justify-between">
        <button
          onClick={onPrevious}
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={onNext}
          className="bg-orange-500 text-white py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

// Step 5 - Bio
const Step5 = ({
  formData,
  handleChange,
  onNext,
  onPrevious,
  loading,
  errors,
}) => {
  return (
    <div className="space-y-4 w-full">
      <h2 className="text-lg font-semibold">Bio</h2>
      <textarea
        name="bio"
        value={formData.bio}
        onChange={handleChange}
        placeholder="Tell us about yourself"
        className="border p-2 rounded w-full"
      />
      {errors.bio && <p className="text-red-500">{errors.bio}</p>}{" "}
      {/* Error handling for bio */}
      <div className="flex justify-between">
        <button
          onClick={onPrevious}
          disabled={loading}
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={onNext}
          disabled={loading}
          className="bg-orange-600 text-white py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
};

// Step 6 - Image Upload
const Step6 = ({
  formData,
  handleChange,
  onSubmit,
  onPrevious,
  errors,
  loading,
}) => {
  return (
    <div className="space-y-4 w-full">
      <h2 className="text-lg font-semibold">Upload Images</h2>
      {Array.from({ length: 5 }).map((_, index) => (
        <div key={index}>
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleChange(e, index)}
          />
        </div>
      ))}
      {errors.images && <p className="text-red-500">{errors.images}</p>}
      <div className="flex justify-between">
        <button
          onClick={onPrevious}
          className="bg-gray-300 text-gray-800 py-2 px-4 rounded"
        >
          Previous
        </button>
        <button
          onClick={onSubmit}
          disabled={loading}
          className="bg-orange-600 text-white py-2 px-4 rounded"
        >
          {loading ? "Loading" : "Submit"}
        </button>
      </div>
    </div>
  );
};
export default MultiStepForm;
